import React, { useEffect, useRef, useState } from 'react'
import DataGridHeader from './DataGridHeader'
import { Box } from '@mui/material'
import { AppColors } from '../../Theme'
import { StyledDataGrid } from './StyledDataGrid'
import {
  DataGridProps,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid'
import { NoRowsOverlay } from './NoRowsOverLay'
import DataGridFooter from './DataGridFooter'
import InnerPageContainer from '../inner-page-container/InnerPageContainer'
import pluralize from 'pluralize'

/**
 *  A stylized wrapper for the MUI DataGrid component.
 *
 * @typedef {Object} EdifyDataGrid
 * @property {string} title - The title of the data grid.
 * @property {string} [error] - An optional error message.
 * @property {Array<any>} rows - An array of data rows.
 * @property {Array<any>} columns - An array of column definitions.
 * @property {(value: GridRowSelectionModel) => void} setSelectionModel - A function to set the row selection model.
 * @property {(row: any) => void} [handleRowClicked] - An optional callback function to handle row click events.
 * @property {JSX.Element} renderHeaderButtons - The JSX element for rendering header buttons.
 * @property {(gridPaginationModel: GridPaginationModel) => void} [loadMoreRows] - An optional function to load more rows in a paginated grid.
 * @property {() => void} [reload] - An optional function to reload the grid.
 * @property {number} rowCount - The total number of rows in the grid.
 */
export interface EdifyDataGridProps extends DataGridProps {
  title: string
  error?: string
  rows: Array<any>
  columns: Array<any>
  setSelectionModel: (value: GridRowSelectionModel) => void
  selectionModel?: GridRowSelectionModel
  handleRowClicked?: (row: any) => void
  renderHeaderButtons?: JSX.Element
  loadMoreRows?: (gridPaginationModel: GridPaginationModel) => void
  reload?: () => void
  rowCount: number
  sortClickedHandler?: (sorter: GridSortModel) => void
  count?: number
  large?: boolean
  noRowsText?: string
  disableSort?: boolean
  checkboxSelection?: boolean
  includeCount?: boolean
  formatTitle?: boolean
  initialSortField?: string
  initSort?: GridSortModel
  extraSlots?: any
}
/**
 * The default limit for pagination.
 *
 * @constant {number}
 */
export const DEFAULT_LIMIT = 20
export const TABLE_ROW_HEIGHT = 64

/**
 *  A stylized wrapper for the MUI DataGrid component.
 *
 * @param {EdifyDataGrid} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */

export const EdifyDataGrid: React.FC<EdifyDataGridProps> = ({
  title,
  rows,
  setSelectionModel,
  renderHeaderButtons,
  loading,
  columns,
  handleRowClicked,
  rowCount,
  paginationMode,
  rowSelectionModel,
  loadMoreRows,
  error,
  sortClickedHandler,
  reload,
  disableSort = false,
  noRowsText = 'No rows found',
  checkboxSelection = true,
  includeCount = true,
  formatTitle = true,
  initSort = [
    {
      field: 'name',
      sort: 'asc',
    },
  ],
  extraSlots,
}) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_LIMIT,
  })
  const prevPaginationModelRef = useRef(paginationModel)
  const [displayCount, setDisplayCount] = useState<number>(rowCount ?? 0)

  const [sorter, setSorter] = useState<GridSortModel>(initSort)

  const handleLoadMore = async (gridPaginationModel: GridPaginationModel) => {
    // Users page is currently not paginated but will be in the future
    if (!loadMoreRows) return
    await loadMoreRows(gridPaginationModel)
    setPaginationModel(gridPaginationModel)
  }

  const isServer = paginationMode === 'server'

  // could do custom loading like this too
  // but need to figure out how not to do with pagination mode
  // if (loading) {
  //   return (
  //     <Box sx={{ backgroundColor: AppColors.baseWhite }}>
  //       <DataGridHeader title={title}></DataGridHeader>
  //       <Box
  //         sx={{
  //           margin: 20,
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Typography>Loading</Typography>
  //       </Box>
  //     </Box>
  //   )
  // }
  //SORTING
  function updateSorter(
    oldSorter: GridSortItem,
    newSorter: GridSortItem,
  ): GridSortItem {
    if (oldSorter.field === newSorter.field) {
      // The fields are the same, revert the sort value
      const newSort = oldSorter.sort === 'asc' ? 'desc' : 'asc'
      return { ...oldSorter, sort: newSort }
    } else {
      // The fields are different, return a new object with the field of the newState and sort as 'desc'
      return { field: newSorter.field, sort: 'desc' }
    }
  }

  const handleSortModelChange = async (model: GridSortModel) => {
    const oldSorter: GridSortItem = model.length === 0 ? sorter[0] : model[0]

    const newSorter: GridSortItem = updateSorter(sorter[0], oldSorter)
    setPaginationModel({ page: 0, pageSize: DEFAULT_LIMIT })
    setSorter([newSorter])
    sortClickedHandler && sortClickedHandler([newSorter])

    // Update the rows with the filtered data from the server
  }
  const getTitle = (title: string) => {
    if (error || !formatTitle || rowCount == -1) {
      return title
    }
    if (includeCount) {
      return pluralize(title, displayCount ?? 0, true)
    }
    return title
  }

  useEffect(() => {
    setDisplayCount(rowCount)
  }, [rowCount])

  // Track and manage pagination state when filters/sorts are applied
  useEffect(() => {
    const prevPaginationModel = prevPaginationModelRef.current
    if (loading) {
    // Only reset pagination when:
    // 1. The current page hasn't changed from previous state (indicating this isn't a pagination action)
    // 2. We're not already on the first page
      if (
        prevPaginationModel.page === paginationModel.page &&
        paginationModel.page !== 0
      ) {
        setPaginationModel({ page: 0, pageSize: DEFAULT_LIMIT })
      }
      prevPaginationModelRef.current = paginationModel
    }
  }, [loading])

  const showFooter = rowCount > DEFAULT_LIMIT

  if (error) {
    return (
      <Box data-testid='EdifyDataGridError'>
        <DataGridHeader title={title}></DataGridHeader>
        <InnerPageContainer
          innerPageError={error}
          innerPageReload={reload || undefined}
          sx={{
            padding: '24px',
            borderTop: 'none',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderLeft: `1px solid ${AppColors.neutral600}`,
            borderRight: `1px solid ${AppColors.neutral600}`,
            borderBottom: `1px solid ${AppColors.neutral600}`,
          }}
        />
      </Box>
    )
  }

  return (
    <>
      <DataGridHeader title={getTitle(title)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: 'auto',
            ml: 24,
          }}
        >
          {renderHeaderButtons && renderHeaderButtons}
        </Box>
      </DataGridHeader>
      {/* 109 is the number to mak that row 64ps when empty */}
      <Box
        sx={{
          height: error ? '250px' : rows.length == 0 ? '109px' : 'auto',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: AppColors.baseWhite,
        }}
      >
        <StyledDataGrid
          sortingMode='server'
          rowHeight={TABLE_ROW_HEIGHT}
          checkboxSelection={checkboxSelection}
          className={!checkboxSelection ? 'no-check-box' : ''}
          disableRowSelectionOnClick
          disableColumnMenu
          onRowSelectionModelChange={(
            rowSelectionModel: GridRowSelectionModel,
          ) => {
            setSelectionModel(rowSelectionModel)
          }}
          rows={rows}
          columns={columns}
          keepNonExistentRowsSelected={true}
          rowCount={rowCount}
          loading={loading}
          pageSizeOptions={[DEFAULT_LIMIT]}
          paginationModel={isServer ? paginationModel : undefined}
          paginationMode={isServer ? 'server' : 'client'}
          onRowClick={handleRowClicked}
          onPaginationModelChange={isServer ? handleLoadMore : undefined}
          rowSelectionModel={rowSelectionModel}
          //This is breaking tables with dynamic headers
          // just the sort icon
          // sortModel={sorter}
          sortModel={disableSort ? [] : sorter}
          onSortModelChange={handleSortModelChange}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay
                text={!error && rowCount == 0 ? noRowsText : undefined}
                error={error}
                reload={reload}
              />
            ),
            footer: () =>
              showFooter ? (
                <DataGridFooter
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  totalCount={rowCount}
                />
              ) : null,
            ...extraSlots,
          }}
        />
      </Box>
    </>
  )
}
