import React, { useEffect, useState } from 'react'
import { IFormSettingsComponent } from './FormSettingsPageViewModel'
import { EdifyFormField } from '../../components/form'
import {
  IFormFilter,
  IFormHeader,
} from '../../../domain/interfaces/IFormHeader'
import { deleteFormFilter, deleteFormHeader } from '../../../data/data'
import {
  AdminCreateFormFilter,
  AdminCreateFormHeader,
  AdminDeleteFormHeader,
  getForm,
  getFormFilters,
} from '../../../domain/domain'
import { EdifyButton } from '../../components/buttons'
import { IFormComponent } from '../../../domain/interfaces/IFormComponent'
import { IForm } from '../../../domain/interfaces/IForm'
import { Box, Button } from '@mui/material'
import { AppColors, AppFonts } from '../../Theme'

interface DynamicHeadersSectionProps {
  form: IForm
  reloadForm: () => void
  // formComponents: IFormComponent
}

const DynamicHeadersSection: React.FC<DynamicHeadersSectionProps> = ({
  form,
  reloadForm,
}) => {
  const { getAllForms } = useOrganizationProvider()
  const [loadedFilters, setLoadedFilters] = useState<boolean>(false)
  const [filters, setFilters] = useState<IFormFilter[] | undefined>(undefined)
  const { id } = form
  const [dynamicHeaderState, setDynamicHeaderState] = useState<any>([])
  const [commonHeaderState, setCommonHeaderState] = useState<any>([])
  const { getHierarchyName } = useOrganizationProvider()
  const [updatingValues, setUpdatingValues] = useState(false)

  const { addColumn, deleteColumn } = useNextColumnNumber(
    form.webHeaders?.map((h) => h.columnNumber).filter((h) => h > 0) || [],
  )
  const replaceName = (name: string) => {
    if (name == '{{data.dataSource.projectName}}')
      return getHierarchyName(0, true)
    if (name == '{{data.dataSource.level1Name}}')
      return getHierarchyName(1, true)
    return name
  }

  function extractFieldsFromForm(formInstance: IForm ) {
    const fields: any[] = []

    function traverseComponents(components: IFormComponent[], level: number,  isParentSupported =true ) {
      if (!components) return

      components.forEach((component) => {
        // Assuming you want to extract fields like 'textfield', 'select', etc.
        if (component.hidden) return 
        const headerMatch = formInstance.webHeaders!.find(
          (fh: any) => fh.key == component.key,
        )
        const mobileMatch = formInstance.mobileHeaders!.find(
          (fh: any) => fh.key == component.key,
        )

        const filterMatch =
          filters && filters.find((fh: any) => fh.key == component.key)
        const { key, label, type } = component

        const isCurrentComponentSupported = isParentSupported && 
        !['datagrid'].includes(component.type)
        fields.push({
          key,
          label: replaceName(label ?? ''),
          type: component.type,
          isHeaderSelected: headerMatch ? true : false,
          isFilterSelected: filterMatch ? true : false,
          isMobileSelected: mobileMatch ? true : false,
          mobileId: mobileMatch ? mobileMatch.id : undefined,
          id: headerMatch ? headerMatch.id : undefined,
          filterId: filterMatch ? filterMatch.id : undefined,
          level: level,
          isCurrentComponentSupported,
        })
        // If the component has nested components, traverse them as well
        if (component.components) {
          traverseComponents(component.components, level + 1, isCurrentComponentSupported)
        }
        if (component.columns) {
          component.columns.forEach((column) => {
            if (column.components) {
              traverseComponents(column.components, level + 1, isCurrentComponentSupported)
            }
          })
        }
      })
    }

    // Start traversal with the top-level components of the form
    
    if (formInstance.components) {
      traverseComponents(formInstance.components, 0)
    }

    // Process duplicates at the end
    const keyCount = fields.reduce((acc, field) => {
      acc[field.key] = (acc[field.key] || 0) + 1
      return acc
    }, {})

    // Mark all instances of duplicate keys
    fields.forEach(field => {
      if (keyCount[field.key] > 1) {
        field.duplicateKey = true
      }
    })
   
    return fields
  }
  const handleFilterToggleChange = async (value: IFormSettingsComponent) => {
    // const res = await deleteFormHeader('eedc19e-1266-4d57-a81b-14e1d2abff1e')
    // return
    setUpdatingValues(true)
    if (value.isFilterSelected && value.filterId) {
      const res = await deleteFormFilter(value.filterId || '')
      setUpdatingValues(false)
      if (res.isLeft()) {
        ErrorToast({ title: 'Error deleting filter' })
        return
      }
      SuccessToast({ title: 'Success deleting filter' })
      const newState = dynamicHeaderState.map((d: any) => {
        return d.key == value.key
          ? { ...d, isFilterSelected: false, filterId: undefined }
          : d
      })
      const newCState = commonHeaderState.map((d: any) => {
        return d.key == value.key
          ? { ...d, isFilterSelected: false, filterId: undefined }
          : d
      })
      setDynamicHeaderState(newState)
      setCommonHeaderState(newCState)
      setFilters(filters?.filter((f) => f.id !== value.filterId))
    } else {
      const res = await AdminCreateFormFilter({
        name: value.label || '',
        key: value.key || '',
        formId: id,
        type: value.type,
      })
      setUpdatingValues(false)
      if (res.isLeft()) {
        ErrorToast({ title: 'Error adding filter' })
        return
      }

      const formFilter = res.right as IFormFilter
      if (formFilter) {
        const newState = dynamicHeaderState.map((d: any) => {
          return d.key == formFilter.key
            ? { ...d, filterId: formFilter.id, isFilterSelected: true }
            : d
        })
        const newCState = commonHeaderState.map((d: any) => {
          return d.key == formFilter.key
            ? { ...d, filterId: formFilter.id, isFilterSelected: true }
            : d
        })
        setUpdatingValues(false)
        setDynamicHeaderState(newState)
        setCommonHeaderState(newCState)
        setFilters([...(filters || []), formFilter])
        SuccessToast({ title: 'Success adding filter' })
      }
    }
  }

  const handleToggleChange = async (value: IFormSettingsComponent) => {
    setUpdatingValues(true)
    if (value.isHeaderSelected && value.id) {
      // delete
      const res = await deleteFormHeader(value.id || '')
      setUpdatingValues(false)
      if (res.isLeft()) {
        ErrorToast({ title: 'Error deleting header' })
        return
      }
      SuccessToast({ title: 'Success deleting header' })
      deleteColumn(res.right?.columnNumber || 0)
      const newState = dynamicHeaderState.map((d: any) => {
        return d.key == value.key
          ? { ...d, isHeaderSelected: false, id: undefined }
          : d
      })
      const newCState = commonHeaderState.map((d: any) => {
        return d.key == value.key
          ? { ...d, isHeaderSelected: false, id: undefined }
          : d
      })
      setDynamicHeaderState(newState)
      setCommonHeaderState(newCState)
    } else {
      const res = await AdminCreateFormHeader({
        columnNumber: addColumn(),
        name: value.label || '',
        key: value.key || '',
        formId: id,
        type: 'DESKTOP',
      })
      setUpdatingValues(false)

      if (res.isLeft()) {
        ErrorToast({ title: 'Error adding header' })
        return
      }

      const formHeader = res.right as IFormHeader
      if (formHeader) {
        const newState = dynamicHeaderState.map((d: any) => {
          return d.key == formHeader.key
            ? {
              ...d,
              id: formHeader.id,
              isHeaderSelected: true,
              columnNumber: res.right?.columnNumber,
            }
            : d
        })
        const newCState = commonHeaderState.map((d: any) => {
          return d.key == formHeader.key
            ? {
              ...d,
              id: formHeader.id,
              isHeaderSelected: true,
              columnNumber: res.right?.columnNumber,
            }
            : d
        })

        setDynamicHeaderState(newState)
        setCommonHeaderState(newCState)
        SuccessToast({ title: 'Success adding header' })
      }
    }
  }

  const handleToggleMobileChange = async (value: IFormSettingsComponent) => {
    setUpdatingValues(true)
    if (value.isMobileSelected && value.mobileId) {
      // delete
      const res = await deleteFormHeader(value.mobileId || '')
      setUpdatingValues(false)
      if (res.isLeft()) {
        ErrorToast({ title: 'Error deleting header' })
        return
      }
      SuccessToast({ title: 'Success deleting header' })
      const newState = dynamicHeaderState.map((d: any) => {
        return d.key == value.key
          ? { ...d, isMobileSelected: false, id: undefined }
          : d
      })
      const newCState = commonHeaderState.map((d: any) => {
        return d.key == value.key
          ? { ...d, isMobileSelected: false, id: undefined }
          : d
      })
      setDynamicHeaderState(newState)
      setCommonHeaderState(newCState)
    } else {
      const dhCount = dynamicHeaderState.filter(
        (d: any) => d.isMobileSelected,
      ).length
      const commonCount = commonHeaderState.filter(
        (d: any) => d.isMobileSelected,
      ).length
      if (dhCount >= 1 || commonCount >= 1) {
        setUpdatingValues(false)
        ErrorToast({ title: 'Only one mobile header allowed' })
        return
      }
      const res = await AdminCreateFormHeader({
        // only one for now
        columnNumber: 1,
        name: value.label || '',
        key: value.key || '',
        formId: id,
        type: 'MOBILE',
      })
      setUpdatingValues(false)

      if (res.isLeft()) {
        ErrorToast({ title: 'Error adding header' })
        return
      }

      const formHeader = res.right as IFormHeader
      if (formHeader) {
        const newState = dynamicHeaderState.map((d: any) => {
          return d.key == formHeader.key
            ? { ...d, id: formHeader.id, isMobileSelected: true }
            : d
        })
        const newCState = commonHeaderState.map((d: any) => {
          return d.key == formHeader.key
            ? { ...d, id: formHeader.id, isMobileSelected: true }
            : d
        })

        setDynamicHeaderState(newState)
        setCommonHeaderState(newCState)
        SuccessToast({ title: 'Success adding header' })

        // setFormHeaderCount(formHeaderCount + 1)
      }
    }
  }

  const deleteAllHeaders = async () => {
    const res = await getForm(form.id)
    if (res.isLeft()) return
    const updatedForm = res.right as IForm

    const webHeaderDeletions = updatedForm.webHeaders!.map(async (fh: any) => {
      const res = await AdminDeleteFormHeader(fh.id)
      if (res.isLeft()) {
        ErrorToast({ title: 'Error Occurred' })
      }
      return res
    })

    const mobileHeaderDeletions = updatedForm.mobileHeaders.map(
      async (fh: any) => {
        const res = await AdminDeleteFormHeader(fh.id)
        if (res.isLeft()) {
          ErrorToast({ title: 'Error Occurred' })
        }
        return res
      },
    )

    const filterDeletions = filters
      ? filters.map(async (fh: any) => {
        const res = await deleteFormFilter(fh.id)
        if (res.isLeft()) {
          ErrorToast({ title: 'Error Occurred' })
        }
        return res
      })
      : []

    setLoadedFilters(false)
    await Promise.all([
      ...webHeaderDeletions,
      ...mobileHeaderDeletions,
      ...filterDeletions,
    ])

    await getAllForms()
    await reloadForm()
  }

  const getCommonHeaders = (form: IForm, filters: IFormFilter[]) => {
    const common = [
      {
        key: 'projectName',
        label: getHierarchyName(0, true),
        type: 'select',
      },
      {
        key: 'level1Name',
        label: getHierarchyName(1, true),
        type: 'select',
      },
      { key: 'createdAt', label: 'Submitted', type: 'datetime' },
      { key: 'updatedAt', label: 'Last Updated', type: 'datetime' },
      {
        key: 'submittedBy',
        label: 'Submitted By',
        type: 'select',
      },
      {
        key: 'ownerName',
        label: 'Owner',
        type: 'select',
      },
      {
        key: 'state',
        label: 'Status',
        type: 'select',
      },
    ]
    const fields: any = []
    common.forEach((component: any) => {
      const match = form.webHeaders!.find((fh: any) => fh.key == component.key)
      const mobileMatch = form.mobileHeaders!.find(
        (fh: any) => fh.key == component.key,
      )
      const filterMatch = filters.find((fh: any) => fh.key == component.key)
      const { key, label } = component
      fields.push({
        key,
        label,
        type: component.type,
        isHeaderSelected: match ? true : false,
        isFilterSelected: filterMatch ? true : false,
        isMobileSelected: mobileMatch ? true : false,
        mobileId: mobileMatch ? mobileMatch.id : undefined,
        filterId: filterMatch ? filterMatch.id : undefined,
        id: match ? match.id : mobileMatch ? mobileMatch.id : undefined,
        level: 0,
      })
    })
    return fields
  }

  const loadFilters = async () => {
    const res = await getFormFilters(id)
    setLoadedFilters(true)
    if (res.right) {
      setFilters(res.right as IFormFilter[])
    }
  }
  const getSupportText = (component: any)=>{
    const deprecatedFilters = [
      'signature',
      'datagrid',
      'file',
      'currency',
      'button',
      'panel',
      'htmlelement',
      'address',
    ]
    const isDeprecated = deprecatedFilters.includes(component.type)
    if (component.duplicateKey) return '(Duplicate Key)'
    return (isDeprecated || !component.isCurrentComponentSupported || component.duplicateKey) ? '(Not Supported)'  : ''
  } 

  useEffect(() => {
    loadFilters()
    if (!loadedFilters) return
    const formComponents = extractFieldsFromForm(form)
    const commonHeaders = getCommonHeaders(form, filters || [])
    setDynamicHeaderState(formComponents)
    setCommonHeaderState(commonHeaders)
  }, [loadedFilters])
  // if(isResetting) return <div>Resetting...</div>
  if (!loadedFilters)
    return (
      <InnerPageContainer
        headerTitle='Loading Dynamic Headers...'
        collapsible
        collapsed={true}
      ></InnerPageContainer>
    )

  return (
    <InnerPageContainer
      headerTitle='Dynamic Headers'
      collapsible
      collapsed={true}
    >
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Box>
          <Box sx={{ marginTop: '24px' }}>
            <EdifyButton
              title='Delete all Headers And Filters (Reset)'
              onClick={deleteAllHeaders}
            />
            <EdifyFormField>
              <Button
                onClick={async () => {
                  const res = await AdminCreateFormHeader({
                    columnNumber: addColumn(),
                    name: 'Yes, but not listed',
                    key: 'location',
                    formId: id,
                    type: 'DESKTOP',
                  })
                }}
                sx={{ display: 'none' }}
              >
                {' '}
                add yes but not listed
              </Button>

              <h3>Common Headers</h3>
              {/* <p>getNextColumnNumbers: {JSON.stringify(getNextColumnNumber())}</p> */}
              <>
                {commonHeaderState.map((fc: any, index: number) => {
                  const isPanel = fc.type == 'container' || fc.type == 'panel'
                  return (
                    <EdifyToggle
                      disabled={updatingValues}
                      key={`${fc.key}-${index}`}
                      text={fc.label}
                      subText={fc.key}
                      type={fc.type}
                      level={fc.level}
                      headerChecked={fc.isHeaderSelected}
                      filterChecked={fc.isFilterSelected}
                      mobileChecked={fc.isMobileSelected}
                      onHeaderChange={() => handleToggleChange(fc)}
                      onMobileHeaderChanged={() => handleToggleMobileChange(fc)}
                      onFilterChange={() => handleFilterToggleChange(fc)}
                      hideSwitch={isPanel}
                    />
                  )
                })}
              </>
            </EdifyFormField>
            <EdifyFormField>
              <h3>Form Specific Headers</h3>
              <>
                {dynamicHeaderState.map((dc: any, index: number) => {
                  // const panels = ['container', 'panel', 'columns','datagrid']
                  const panels = ['container', 'panel', 'columns','datagrid','file','content']
                  const isPanel = panels.includes(dc.type)
                  const notSupported = [
                    'button',
                    'panel',
                    'htmlelement',
                    'address',
                    'well',
                    // 'datagrid',
                    // 'container',
                    'file'
                  ]
                  const deprecatedFilters = [
                    'signature',
                    'datagrid',
                    'file',
                    'currency',
                    'button',
                    'panel',
                    'htmlelement',
                    'address',
                  ]
                  // add back when duplicate keys fixed
                  const hideLabels = [
                    'level1',
                    'project',
                  ]
                  if (notSupported.includes(dc.type)) return null
                  if (hideLabels.includes(dc.key)) return null
                  return (
                    <EdifyToggle
                      disabled={updatingValues}
           
                      key={`${dc.key}-${index}`}
                      text={`${dc.label} ${getSupportText(dc)}`}
                      // subText={`key: ${dc.key} chainedKey:${dc.chainedKey}`}
                      subText={`${dc.key}`}
                      headerChecked={dc.isHeaderSelected}
                      filterChecked={dc.isFilterSelected}
                      mobileChecked={dc.isMobileSelected}
                      onHeaderChange={() => handleToggleChange(dc)}
                      onMobileHeaderChanged={() => handleToggleMobileChange(dc)}
                      onFilterChange={() => handleFilterToggleChange(dc)}
                      hideSwitch={isPanel}
                      level={dc.level}
                      type={dc.type}
                    />
                  )
                })}
              </>
            </EdifyFormField>
          </Box>
        </Box>
      </Box>
    </InnerPageContainer>
  )
}
const getStyles = (type: string) => {
  const isPanel = type == 'container' || type == 'panel'
  if (isPanel) {
    return { backgroundColor: AppColors.neutral100 }
  }
  return {}
}

export default DynamicHeadersSection

import { SxProps, Typography } from '@mui/material'
import EdifySwitch from '../../components/form/EdifySwitch/EdifySwitch'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import useNextColumnNumber from '../../../core/hooks/useNextColumn'

interface Props {
  text: string
  subText: string
  type: string
  level: number
  headerChecked?: boolean
  filterChecked?: boolean
  mobileChecked?: boolean
  onHeaderChange: () => void
  onFilterChange: () => void
  onMobileHeaderChanged: () => void
  hideSwitch?: boolean
  sx?: SxProps
  disabled?: boolean
}
const EdifyToggle: React.FC<Props> = ({
  text,
  subText,
  type,
  headerChecked,
  level,
  filterChecked,
  mobileChecked,
  onHeaderChange,
  onFilterChange,
  onMobileHeaderChanged,
  sx,
  hideSwitch,
  disabled = false,
}) => {
  return (
    <Box
      sx={{
        paddingLeft: level * 30 + 20 + 'px',
        margin: '24px 0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'space-between',
          justifyContent: 'space-between',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)',
          borderRadius: '6px',
          border: '1px solid rgba(0, 0, 0, 0.20)',
          width: '750px',
          padding: '12px',
          ...getStyles(type),
          ...sx,
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '16px',
          }}
        >
          <Typography
            sx={{ ...AppFonts.textSMedium, color: AppColors.gray700 }}
          >
            Label: {text}
          </Typography>
          <Typography
            sx={{ ...AppFonts.textXSMedium, color: AppColors.gray50 }}
          >
            Key: {subText}
          </Typography>
          <Typography
            sx={{ ...AppFonts.textXSMedium, color: AppColors.gray50 }}
          >
            Type: {type}
          </Typography>
        </Box>
        {/* {!hideSwitch &&  */}
        {!hideSwitch && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '80px' }}>
              <Typography
                sx={{ ...AppFonts.textSMedium, color: AppColors.gray700 }}
              >
                Mobile
              </Typography>
              <EdifySwitch
                disabled={disabled}
                checked={mobileChecked}
                onChange={onMobileHeaderChanged}
              />
            </Box>
            <Box sx={{ width: '80px' }}>
              <Typography
                sx={{ ...AppFonts.textSMedium, color: AppColors.gray700 }}
              >
                Web
              </Typography>
              <EdifySwitch
                disabled={disabled}
                checked={headerChecked}
                onChange={onHeaderChange}
              />
            </Box>
            <Box sx={{ width: '80px' }}>
              <Typography
                sx={{ ...AppFonts.textSMedium, color: AppColors.gray700 }}
              >
                Filter
              </Typography>
              <EdifySwitch
                disabled={disabled}
                checked={filterChecked}
                onChange={onFilterChange}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
