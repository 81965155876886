import { REACT_APP_SERVER_API } from '../../../config'
import { getFromLocalStorage, getInitialToken } from '../../../data/data'
import { IForm } from '../../../domain/interfaces/IForm'
import { IFormComponent } from '../../../domain/interfaces/IFormComponent'
import { IFormFilter } from '../../../domain/interfaces/IFormHeader'

// OLD
// const urlData = [
//   {
//     formIOsource: '{{data.contractorsURL}}?{{data.contractorsParams}}',
//     getAllURL: `${REACT_APP_SERVER_API}/minimal/contractors?&limit=100&skip=0`,
//     url: `${REACT_APP_SERVER_API}/contractors`
//   },
//   {
//     formIOsource: '{{data.responsiblePartyURL}}?{{data.responsiblePartyParams}}',
//     getAllURL: `${REACT_APP_SERVER_API}/minimal/contractors?&limit=100&skip=0`,
//     url: `${REACT_APP_SERVER_API}/contractors`
//   }
// ]

// const getUrl = (formIOsource: string) => {
//   const url = urlData.find((url) => url.formIOsource == formIOsource)
//   return url
// }
const correctiveActionKeys = ['correctiveAction', 'correctiveActionGeneral']

export const findComponent = (components: IFormComponent[], key: string, isFromCA: boolean, isFromScheduler: boolean, level: number): IFormComponent | undefined => {
  for (const component of components) {
    if (component.key === key) {
      return { ...component, isFromCA, isFromScheduler, level: level }
    }
    if (component.components) {
      const isCA = isFromCA || correctiveActionKeys.includes(component.key ?? '')
      const isScheduler = isFromScheduler || component.key == 'scheduler'
      const found = findComponent(component.components, key, isCA, isScheduler, level + 1)
      if (found) {
        return found
      }
    }
    if (component.columns) {
      const isScheduler = isFromScheduler || component.key == 'scheduler'
      for (const column of component.columns) {
        if (column.components) {
          const found = findComponent(column.components, key, false, isScheduler, level + 1)
          if (found) {
            return found
          }
        }
      }
    }
  }
  return undefined
}

const commonKeys = ['projectName', 'level1Name', 'createdAt', 'updatedAt', 'submittedBy', 'ownerName', 'state']
export const commonComponents = [
  { key: 'projectName', label: 'Project', type: 'select', data: { url: 'projectsURL' } },
  { key: 'level1Name', label: 'Division', type: 'select', data: { url: 'level1URL' } },
  { key: 'createdAt', label: 'Submitted', type: 'datetime' },
  { key: 'updatedAt', label: 'Last Updated', type: 'datetime' },
  { key: 'submittedBy', label: 'Submitted By', type: 'select', data: { url: 'usersURL' } },
  { key: 'ownerName', label: 'Owner', type: 'select', data: { url: 'usersURL' } },
  { key: 'state', label: 'Status', type: 'select', data: {} },
]

export const normalizeDynamicHeadersForTableView = (form: IForm) => {
  if (!form || !form.webHeaders) {
    return undefined
  }
  const normalizedDynamicHeaders = form.webHeaders.map((wh) => {
    // corrective action data and scheduler data are store separately in submission data.
    // const isCa = wh.key == 'correctiveAction'
    const isCa = correctiveActionKeys.includes(wh.key ?? '')
    const isScheduler = wh.key == 'scheduler'
    const component = findComponent([...form.components!, ...commonComponents], wh.key, isCa, isScheduler, 0)

    if (!component) return {
      id: wh.id,
      name: `Header Not Found: ${wh.name}`,
      key: wh.key,
      type: 'textField',
      values: 'ERROR',
      label: 'ERROR',
      loadData: false,
      isFromCa: false,
      isFromScheduler: false,
      isCommon: false,
      level: 0,
      data: undefined,
      url: undefined,
      sortRank: 10
    }

    const { data } = component
    const source = component.data?.url ? component.data.url : ''
    // const sourceUrl = getUrl(source)

    const url = data && data.url ? getEntity(data.url) : undefined
    const loadData = url ? true : false
    // const loadData = sourceUrl && sourceUrl.url ? true : false

    return {
      id: wh.id,
      name: wh.name,
      key: wh.key,
      type: component?.type ?? 'textField',
      columnNumber: wh.columnNumber,
      values: data?.values ?? component.values,
      label: component.label, // might be same as name
      // sourceURL: sourceUrl,
      loadData: loadData ? true : false,
      isFromCa: component.isFromCA,
      isFromScheduler: component.isFromScheduler,
      isCommon: commonKeys.includes(wh.key),
      level: component.level,
      data: '**'
    }
  })
  return normalizedDynamicHeaders.filter((header) => header.label !== 'ERROR')
}
const getEntity = (url: any) => {
  if (url == undefined) return undefined

  if (url.includes('usersURL'))
    return 'users'
  if (url.includes('contractorsURL'))
    return 'contractors'
  if (url.includes('level1URL'))
    return 'levels'
  if (url.includes('projectsURL'))
    return 'projects'
  if (url.includes('responsiblePartyParams'))
    return 'responsibleParty'
  if (url.includes('signaturesURL'))
    return 'users'

  if (url.includes('contractorContactsURL'))
    return 'contractor_contacts'
  return undefined

}

export const normalizeDynamicFiltersForTableView = (form: IForm, dynamicFilter: IFormFilter[]) => {
  if (!form) {
    return undefined
  }
  const getSortRank = (type: string, loadData: boolean) => {

    if (['checkbox', 'selectboxes', 'select', 'radio'].includes(type)) {
      return 0
    }
    if (loadData) return 1
    if (['date', 'datetime'].includes(type)) {
      return 2
    }
    return 3
  }
  const normalizedDynamicHeaders = dynamicFilter.map((wh, i:number) => {
    // corrective action data and scheduler data are store separately in submission data.
    // const isCa = 
    const isCa = correctiveActionKeys.includes(wh.key ?? '')
    const isScheduler = wh.key == 'scheduler'
    const component = findComponent([...form.components!, ...commonComponents], wh.key, isCa, isScheduler, 0)
    if (!component) return {
      id: wh.id,
      name: wh.name,
      key: wh.key,
      type: 'textField',
      values: 'ERROR',
      label: 'ERROR',
      loadData: false,
      isFromCa: false,
      isFromScheduler: false,
      isCommon: false,
      level: 0,
      data: '**',
      url: undefined,
      sortRank: 10
    }

    const { data } = component
    const source = component.data?.url ? component.data.url : ''
    // const sourceUrl = getUrl(source)
    const url = data && data.url ? getEntity(data.url) : undefined
    const loadData = url ? true : false
    return {
      id: wh.id,
      name: wh.name,
      key: wh.key,
      type: component?.type ?? 'textField',
      values: data?.values ?? component.values,
      // label: component.label +'!!', // might be same as name
      label: wh.name,
      // sourceURL: sourceUrl,
      loadData: url ? true : false,
      isFromCa: component.isFromCA,
      isFromScheduler: component.isFromScheduler,
      isCommon: commonKeys.includes(wh.key),
      level: component.level,
      data: data,
      url,
      sortRank: getSortRank(component?.type, loadData)
    }
  })
  // return normalizedDynamicHeaders
  return normalizedDynamicHeaders.filter((header) => header.label !== 'ERROR')
}

function extractFormParameter(url: string): string | null {
  const urlParams = new URLSearchParams(url.split('?')[1])
  return urlParams.get('form')
}


export function isImageFile(url: string): boolean {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg']
  const formValue = extractFormParameter(url)

  if (!formValue) {
    return false
  }

  const lowerCaseFormValue = formValue.toLowerCase()

  if (lowerCaseFormValue.endsWith('.pdf')) {
    return false
  }

  // Check if the form parameter contains any of the image extensions
  return imageExtensions.some(extension => lowerCaseFormValue.includes(extension))
}


export const socImageStringReplace = (submisson: any) => {
  let copySub = JSON.parse(JSON.stringify(submisson))


  console.log('copySub', copySub)

  const token = getInitialToken()
  const org = getFromLocalStorage('org')
  // for anon
  // const orgId = org ? JSON.parse(org).id : getOrgIdFromUrl()
  const orgId = org ? JSON.parse(org).id : ''

  const sub = JSON.stringify(copySub)
  const regex = /\?token=([^&]*)&orgId=([^&]*)&/g
  const regex2 = /&token=([^&]*)&orgId=([^&]*)&/g
  const updatedSub = sub.replace(
    regex,
    (match, p1, p2) => `?token=${token}&orgId=${orgId}&`,
  ).replace(
    regex2,
    (match, p1, p2) => `&token=${token}&orgId=${orgId}&`,
  )

  copySub = JSON.parse(updatedSub)
  return copySub

}
